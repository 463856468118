import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSignup } from "../../hooks/useSignup";

export default function SignUp() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [level, setLevel] = useState("");
  const [displayName, setDisplayName] = useState("");
  const { error, signup, isPending } = useSignup();

  const handleSubmit = (e) => {
    e.preventDefault();
    signup(displayName, email, password, level);
  };

  return (
    <div className="custom-container login-container">
      <div className="login-sub-container">
        <div className="lc-title">
          <h3 className="mb-0">Sign up</h3>
          <p>to start learning</p>
        </div>
        <div className="form-group mt-5">
          <form className="form-container" onSubmit={handleSubmit}>
            <div className="email column-flex">
              <label htmlFor="email">Username</label>
              <input
                type="text"
                name="username"
                value={displayName}
                onChange={(e) => setDisplayName(e.target.value)}
                placeholder="Create a username"
              />
            </div>
            <div className="email column-flex">
              <label htmlFor="email">Email Address</label>
              <input
                type="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your registered email"
              />
            </div>
            <div className="create-password column-flex">
              <label htmlFor="password">Create Password</label>
              <input
                type="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Create password"
              />
            </div>
            <div className="confirm-password column-flex">
              <label htmlFor="level">Choose your Level</label>
              <select
                name="level"
                value={level}
                onChange={(e) => setLevel(e.target.value)}
              >
                <option value="">Choose your Level</option>
                <option value="beginner">Beginner</option>
                <option value="intermediate">Intermediate</option>
                <option value="advanced">Advanced</option>
              </select>
            </div>

            <div className="forgot-pass">
              <p>
                By signing up, you agree to KMA{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://krizbeatzmusicacademy.com/#/terms"
                >
                  Terms
                </a>{" "}
                and{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://krizbeatzmusicacademy.com/#/privacy-policy"
                >
                  Privacy policies
                </a>
              </p>
            </div>
            <div className="submit-btn">
              {!isPending && (
                <button type="submit" className="button primary-btn">
                  Sign up
                </button>
              )}
              {isPending && (
                <button type="button" className="button primary-btn" disabled>
                  <span className="spinner-border spinner-border-sm"></span>{" "}
                  Signing up...
                </button>
              )}
            </div>
            {error && <p className="error">{error}</p>}
          </form>
          {/* <LoginWithGoogle /> */}
          <p className="mt-4 text-center">
            Already have an account? <Link to="/login">Log in</Link>
          </p>
        </div>
      </div>
    </div>
  );
}
