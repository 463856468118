import React, { useState } from 'react'
import logo from '../assets/images/kma-d-logo.png'
import { otherSidebarLinks, sidebarModules } from './sidebar-modules'
import { useLogout } from '../hooks/useLogout'

import { NavLink } from 'react-router-dom'

import './Sidebar.css'

const Sidebar = () => {
  const [hideSidebar, setHideSidebar] = useState(false)

  const openSidebar = () => {
    setHideSidebar(!hideSidebar)
  }

  const { logout } = useLogout()
  return (
    <>
      {/* Opan and close Icon */}
      <span onClick={openSidebar} className='open-close-icon' role='button'>
        {!hideSidebar ? (
          <i className='fa-solid fa-bars'></i>
        ) : (
          <i className='fa-solid fa-circle-chevron-left'></i>
        )}
      </span>

      {/* Sidebar Icons */}
      <div
        className={hideSidebar ? 'sidebar-container' : 'sidebar-container-hide'}
      >
        <div className='sidebar-logo'>
          <img src={logo} alt='brand logo of kma' />
          <div className='divider sb-divider'></div>
        </div>
        <div className='flex-links'>
          <div className='links'>
            {sidebarModules.map(({ id, title, link, icon }) => {
              return (
                <ul key={id} className='module-link'>
                  <NavLink end to={link}>
                    <span className='sb-icon'>{icon}</span>
                    <li>{title}</li>
                  </NavLink>
                </ul>
              )
            })}
          </div>
          <div className='sb-links'>
            {otherSidebarLinks.map(({ id, title, link, icon }) => {
              return (
                <ul key={id}>
                  <NavLink to={link}>
                    <span className='sb-icon'>{icon}</span>
                    <li>{title}</li>
                  </NavLink>
                </ul>
              )
            })}
          </div>
          <div className='button-container'>
            <button className='button logout-btn' onClick={logout}>
              <i className='fa-solid fa-right-from-bracket'></i>
              <span>Logout</span>
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default Sidebar
