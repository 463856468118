import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./Home.css";

export class Home extends Component {
  render() {
    return (
      <>
        <div className="home-container">
          <div className="left-contents">
            <h5>Ready to become a Producer?</h5>
            <p>
              Login to your dashboard or click on create account if you don't
              have an account and start your learning journey.
            </p>
          </div>
          <div className="right-contents">
            <h3>Welcome back!</h3>
            <div className="act-btn">
              <Link to="/login">
                <button className="button primary-btn">
                  Login to your dashboard
                </button>
              </Link>
              <Link to="/signup">
                <button className="button create-act-btn">
                  Create an account
                </button>
              </Link>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Home;
