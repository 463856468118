const icons = [
  <i className="fa-solid fa-lines-leaning"></i>,
  <i className='fa-solid fa-award'></i>,
  <i className='fa-solid fa-gear'></i>,
  <i className='fa-solid fa-user'></i>
]

const sidebarModules = [
  {
    id: '1',
    title: 'Dashboard',
    icon: icons[0],
    link: '/dashboard/feed'
  },
  {
    id: '2',
    title: 'Courses',
    icon: icons[0],
    link: '/dashboard/modules'
  },
  // {
  //   id: '3',
  //   title: 'Module 2',
  //   icon: icons[0],
  //   link: '/module-2'
  // },
  // {
  //   id: '4',
  //   title: 'Module 3',
  //   icon: icons[0],
  //   link: '/module-3'
  // },
  // {
  //   id: '5',
  //   title: 'Module 4',
  //   icon: icons[0],
  //   link: '/module-4'
  // },
  // {
  //   id: '6',
  //   title: 'Module 5',
  //   icon: icons[0],
  //   link: '/module-5'
  // },
]

const otherSidebarLinks = [
  {
    id: '6',
    title: 'Certicate',
    icon: icons[1],
    link: '/certificate'
  },
  {
    id: '7',
    title: 'Settings',
    icon: icons[2],
    link: '/settings'
  },
  {
    id: '8',
    title: 'Account',
    icon: icons[3],
    link: '/account'
  }
]

export { sidebarModules, otherSidebarLinks }
