import React, { useEffect, useState } from "react";
import "./DashboardFeed.css";
import { Link } from "react-router-dom";
import { db, auth } from "../firebase/config";

const DashboardFeed = () => {
  const downloadSamplePackPath = "https://bit.ly/3yNnIXc";
  const [userLevel, setUserLevel] = useState("");
  const [courseDescription, setCourseDescription] = useState("");
  const [numModules, setNumModules] = useState(0);
  const [numLessons, setNumLessons] = useState(0);
  const currentUser = auth.currentUser;

  useEffect(() => {
    const fetchUserLevel = async () => {
      try {
        const userDoc = await db
          .collection("students")
          .doc(currentUser.uid)
          .get();
        if (userDoc.exists) {
          const userData = userDoc.data();
          setUserLevel(userData.level);
        }
      } catch (error) {
        console.error("Error fetching user level:", error);
      }
    };

    const fetchCourseData = async () => {
      try {
        const coursesSnapshot = await db
          .collection("courses")
          .where("level", "==", userLevel)
          .get();
        if (!coursesSnapshot.empty) {
          const courseData = coursesSnapshot.docs[0].data();
          setCourseDescription(courseData.description);

          // Fetch modules from subcollection
          const modulesSnapshot = await db
            .collection("courses")
            .doc(coursesSnapshot.docs[0].id)
            .collection("modules")
            .get();
          const numModules = modulesSnapshot.size;
          setNumModules(numModules);

          // Fetch lessons from all modules
          let totalLessons = 0;
          for (const moduleDoc of modulesSnapshot.docs) {
            const moduleData = moduleDoc.data();
            const lessonsSnapshot = await db
              .collection("courses")
              .doc(coursesSnapshot.docs[0].id)
              .collection("modules")
              .doc(moduleDoc.id)
              .collection("lessons")
              .get();
            const numLessons = lessonsSnapshot.size;
            totalLessons += numLessons;
          }
          setNumLessons(totalLessons);
        }
      } catch (error) {
        console.error("Error fetching course data:", error);
      }
    };

    if (currentUser) {
      fetchUserLevel();
      fetchCourseData();
    }
  }, [currentUser, userLevel]);

  return (
    <div className="dashboard-feed mt-3">
      <div className="course-information">
        <div className="course-detail">
          <h6>Course Title:</h6>
          <p>{userLevel}</p>
          <ul className="course-info-list">
            <li>{numModules} Modules</li>
            <li>{numLessons} Lessons</li>
            <li>4 Assessment</li>
            <li>1 Certificate</li>
          </ul>
          <Link to="/dashboard/modules">
            <button className="start-course-btn primary-btn button">
              Start Course
            </button>
          </Link>
        </div>
        <div className="sample-pack-container">
          <h6 className="sp-name">Krizbeatz Music Academy Sample Packs</h6>
          <a
            href={downloadSamplePackPath}
            download="true"
            className="button dld-btn mt-5"
          >
            Download now <i className="fa-solid fa-download"></i>
          </a>
        </div>
      </div>
      <div className="course-descriptions mt-5">
        <div className="description-container">
          <h6 className="title-course">Course Description</h6>
          <p className="desc-details">{courseDescription}</p>
        </div>
        <div className="detailed-info mt-5">
          <h6 className="di-title mb-4">Course Details</h6>
          <ul className="ps-0">
            <li className="mb-4">
              <i className="fa-solid fa-users"></i> 17k Learners
            </li>
            <li className="mb-4">
              <i className="fa-solid fa-calendar"></i> April 1, 2023 Last
              updated
            </li>
            <li className="mb-4">
              <i className="fa-solid fa-wand-magic-sparkles"></i> {userLevel}{" "}
              Level
            </li>
            <li className="mb-4">
              <i className="fa-solid fa-laptop"></i> Best to access on desktop
            </li>
            <li className="mb-4">
              <i className="fa-solid fa-globe"></i> English Language
            </li>
            <li className="mb-4">
              <i className="fa-solid fa-award"></i> Certificate of Completion
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default DashboardFeed;
