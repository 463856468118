import { useState, useEffect } from 'react'
import { auth } from '../firebase/config'
import { useAuthContext } from '../hooks/useAuthContext'
import { toast } from 'react-toastify'

export const useResetPassword = () => {
  const [isCancelled, setIsCancelled] = useState(false)
  const [error, setError] = useState(null)
  const [isPending, setIsPending] = useState(false)
  const { dispatch } = useAuthContext()

  const resetPassword = async email => {
    setError(null)
    setIsPending(true)

    try {
      await auth.sendPasswordResetEmail(email, {
        url: 'http://localhost:3000/login'
      })

      dispatch({
        type: 'LOGIN'
      })

      if(!isCancelled) {
        setIsPending(false)
        toast.success('Reset link was sent!')
      }

    } catch (err) {
      if (!isCancelled) {
        setError(err.message)
        setIsPending(false)
      }
    }
  }

  useEffect(() => {
    return () => setIsCancelled(true)
  }, [])

  return { error, resetPassword, isPending }
}
