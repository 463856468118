import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useResetPassword } from '../../hooks/useResetPassword'
import { ToastContainer } from 'react-toastify'

export default function ResetPassword () {
  const [email, setEmail] = useState()
  const { resetPassword, isPending, error } = useResetPassword()

  const handleSubmit = e => {
    e.preventDefault()
    resetPassword(email)
  }

  return (
    <div className='custom-container reset-container'>
      <div className='login-sub-container'>
        <div className='lc-title'>
          <h4 className='mb-0'>Reset your password</h4>
        </div>
        <div className='form-group mt-5'>
          <form className='form-container' onSubmit={handleSubmit}>
            <div className='email column-flex'>
              <label htmlFor='email'>Enter your email to reset password</label>
              <input
                type='email'
                name='email'
                placeholder='Enter your email address'
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </div>
            <div className='submit-btn'>
              {!isPending && (
                <button className='button primary-btn'>
                  Send reset link
                </button>
              )}
              {isPending && <button type='button' className='button primary-btn' disabled>
                <span className='spinner-border spinner-border-sm'></span>{' '}
                Please wait...
              </button>}
            </div>
            <p>
              Remember password? <Link to='/login'>Login</Link>
            </p>
            {error && <div className='error'>{error}</div>}
            <ToastContainer />
          </form>
        </div>
      </div>
    </div>
  )
}
