import React, { useState } from 'react'
import { Link } from 'react-router-dom'
// import LoginWithGoogle from '../../components/login_with_google/LoginWithGoogle'

import { useLogin } from '../../hooks/useLogin'

import './Login.css'

export default function Login () {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const { error, login, isPending } = useLogin()

  const handleSubmit = e => {
    e.preventDefault()
    login(email, password)
  }

  return (
    <div className='custom-container login-container'>
      <div className='login-sub-container'>
        <div className='lc-title'>
          <h3 className='mb-0'>Log in</h3>
          <p>to start learning</p>
        </div>
        <div className='form-group mt-5'>
          <form className='form-container' onSubmit={handleSubmit}>
            <div className='email column-flex'>
              <label htmlFor='email'>Email Address</label>
              <input
                type='email'
                name='email'
                placeholder='Enter your registered email'
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </div>
            <div className='password column-flex'>
              <label htmlFor='password'>Password</label>
              <input
                type='password'
                name='password'
                placeholder='Enter your password'
                value={password}
                onChange={e => setPassword(e.target.value)}
              />
            </div>
            <div className='forgot-pass'>
              <Link to='/reset-password'>Forgot password?</Link>
            </div>
            <div className='submit-btn'>
              {!isPending && (
                <button type='submit' className='button primary-btn'>
                  Log in
                </button>
              )}
              {isPending && (
                <button type='button' className='button primary-btn' disabled>
                  <span className="spinner-border spinner-border-sm"></span> logging in...
                </button>
              )}
            </div>
            {error && <p className='error'>{error}</p>}
          </form>
          {/* <LoginWithGoogle /> */}
          <p className='mt-4 text-center'>
            Don't have an account? <Link to='/signup'>Sign up</Link>
          </p>
        </div>
      </div>
    </div>
  )
}
