import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import styles from "./modules.module.css";
import { db, auth } from "../../firebase/config";

export default function Module1() {
  const [modules, setModules] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchModules = async () => {
      try {
        const currentUser = auth.currentUser;
        const userDoc = await db
          .collection("students")
          .doc(currentUser.uid)
          .get();
        if (userDoc.exists) {
          const userData = userDoc.data();
          const userLevel = userData.level;

          const coursesSnapshot = await db
            .collection("courses")
            .where("level", "==", userLevel)
            .get();
          if (!coursesSnapshot.empty) {
            const courseId = coursesSnapshot.docs[0].id;
            const modulesSnapshot = await db
              .collection("courses")
              .doc(courseId)
              .collection("modules")
              .orderBy("index") // Sort by index field
              .get();

            const fetchedModules = modulesSnapshot.docs.map((doc) => {
              const moduleData = doc.data();
              return {
                moduleId: doc.id,
                courseId: courseId,
                moduleTitle: moduleData.title,
                moduleLevel: moduleData.level,
                moduleDescription: moduleData.description,
              };
            });

            setModules(fetchedModules);
          }
        }
      } catch (error) {
        console.error("Error fetching modules:", error);
      }
    };

    fetchModules();
  }, []);

  const handleBannerClick = (module) => {
    navigate(`/dashboard/lessons/${module.moduleId}`, {
      state: {
        courseId: module.courseId,
        moduleId: module.moduleId,
      },
    });
  };

  return (
    <>
      {/* Module Banners */}
      {modules.map((module) => (
        <div
          key={module.moduleId}
          className={styles["course-cover"]}
          onClick={() => handleBannerClick(module)}
        >
          <p>{module.moduleTitle}</p>
          <small>{module.moduleLevel}</small>
          <h2>{module.moduleDescription}</h2>
          <div className={styles["action-btns"]}>
            <button
              className="button outline-btn"
              onClick={() => handleBannerClick(module)}
            >
              Go to Lessons
            </button>
          </div>
        </div>
      ))}
    </>
  );
}
