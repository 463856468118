import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { db } from "../../firebase/config";
import { Layout, Button, Modal, Menu } from "antd";
import {
  EyeInvisibleOutlined,
  EyeOutlined,
  CheckCircleOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import styles from "./lessons.module.css";

const { Header, Content, Sider } = Layout;

export default function Lessons() {
  const location = useLocation();
  const { courseId, moduleId } = location.state || {};
  const [lessons, setLessons] = useState([]);
  const [selectedLesson, setSelectedLesson] = useState(null);
  const [courseMetadata, setCourseMetadata] = useState({});
  const [moduleMetadata, setModuleMetadata] = useState({});
  const [isMobileView, setIsMobileView] = useState(false);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    const fetchLessons = async () => {
      try {
        if (courseId && moduleId) {
          const lessonsSnapshot = await db
            .collection("courses")
            .doc(courseId)
            .collection("modules")
            .doc(moduleId)
            .collection("lessons")
            .orderBy("index")
            .get();

          const fetchedLessons = lessonsSnapshot.docs.map((doc) => {
            const lessonData = doc.data();
            return {
              lessonId: doc.id,
              title: lessonData.title,
              description: lessonData.description,
              videoUrl: lessonData.videoUrl,
              watched: lessonData.watched || false,
              videoLength: lessonData.videoLength || "N/A", // Video length or "N/A" if not available
            };
          });

          setLessons(fetchedLessons);
        }
      } catch (error) {
        console.error("Error fetching lessons:", error);
      }
    };

    fetchLessons();
  }, [courseId, moduleId]);

  useEffect(() => {
    const fetchCourseMetadata = async () => {
      try {
        if (courseId) {
          const courseSnapshot = await db
            .collection("courses")
            .doc(courseId)
            .get();
          const courseData = courseSnapshot.data();
          setCourseMetadata({
            name: courseData.name,
            description: courseData.description,
          });
        }
      } catch (error) {
        console.error("Error fetching course metadata:", error);
      }
    };

    const fetchModuleMetadata = async () => {
      try {
        if (moduleId) {
          const moduleSnapshot = await db
            .collection("modules")
            .doc(moduleId)
            .get();
          const moduleData = moduleSnapshot.data();
          setModuleMetadata({
            name: moduleData.name,
            description: moduleData.description,
          });
        }
      } catch (error) {
        console.error("Error fetching module metadata:", error);
      }
    };

    fetchCourseMetadata();
    fetchModuleMetadata();
  }, [courseId, moduleId]);

  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth <= 768;
      setIsMobileView(isMobile);
    };

    handleResize(); // Check initial viewport width

    const handleResizeDebounced = debounce(handleResize, 200); // Debounce the resize event

    window.addEventListener("resize", handleResizeDebounced);

    return () => {
      window.removeEventListener("resize", handleResizeDebounced);
    };
  }, []);

  const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func.apply(this, args), delay);
    };
  };

  const handleLessonClick = (lesson) => {
    setSelectedLesson(lesson);
  };

  const handleVideoEnd = () => {
    const updatedLessons = lessons.map((lesson) =>
      lesson.lessonId === selectedLesson.lessonId
        ? { ...lesson, watched: true }
        : lesson
    );
    setLessons(updatedLessons);
    setSelectedLesson(null);
  };

  const toggleMenu = () => {
    setIsMenuVisible(!isMenuVisible);
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        width={isMobileView ? 0 : 200}
        collapsible
        collapsed={!isMenuVisible}
        onCollapse={toggleMenu}
        theme="light"
      >
        <Menu theme="light" mode="inline">
          <Menu.Item key="1" onClick={toggleMenu}>
            {isMenuVisible ? (
              <>Course Content</>
            ) : (
              <>
                <EyeOutlined />
              </>
            )}
          </Menu.Item>
        </Menu>
        {isMenuVisible && (
          <Menu theme="light" mode="inline">
            {lessons.map((lesson) => (
              <Menu.Item
                key={lesson.lessonId}
                onClick={() => handleLessonClick(lesson)}
                icon={
                  lesson.watched ? (
                    <CheckCircleOutlined />
                  ) : (
                    <VideoCameraOutlined />
                  )
                }
              >
                {lesson.title}
              </Menu.Item>
            ))}
          </Menu>
        )}
      </Sider>
      <Layout className="site-layout">
        {isMobileView && (
          <Button
            type="primary"
            style={{
              display: isMobileView ? "block" : "none",
              height: "48px", // Set the button height
              backgroundColor: "black", // Change the button color to black
              color: "white", // Set text color to white
              border: "5px", // Remove the button border
            }}
            onClick={() => setIsModalVisible(true)}
          >
            Select Video
          </Button>
        )}
        <Content
          style={{
            margin: isMobileView ? 0 : "24px 16px 0",
            overflow: "initial",
          }}
        >
          <div
            className={styles.site_layout_background}
            style={{
              padding: 24,
              minHeight: 360,
              background: "#fff",
              overflow: "hidden",
            }}
          >
            <div className={styles.videoPlayer}>
              {selectedLesson ? (
                <video
                  controls
                  src={selectedLesson.videoUrl}
                  onEnded={handleVideoEnd}
                  className={styles.video}
                />
              ) : (
                <div className={styles.noLesson}>
                  Select a lesson to play the video
                </div>
              )}
            </div>

            {selectedLesson && (
              <div className={styles.lessonInformation}>
                <h3>Overview</h3>
                <p>
                  Title: {selectedLesson.title}
                  <br />
                  Description: {selectedLesson.description}
                </p>
                <div className={styles.lessonMetadata}>
                  <h4>About Course</h4>
                  <p>
                    Course Name: {courseMetadata.name}
                    <br />
                    Course Description: {courseMetadata.description}
                  </p>
                  <p>
                    Module Name: {moduleMetadata.name}
                    <br />
                    Module Description: {moduleMetadata.description}
                  </p>
                </div>
              </div>
            )}
          </div>
        </Content>
      </Layout>
      <Modal
        title="Select a Video"
        centered
        visible={isModalVisible}
        onOk={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}
        bodyStyle={{
          // display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Menu theme="light" mode="vertical">
          {lessons.map((lesson) => (
            <Menu.Item
              key={lesson.lessonId}
              onClick={() => handleLessonClick(lesson)}
            >
              {lesson.title}
            </Menu.Item>
          ))}
        </Menu>
      </Modal>
    </Layout>
  );
}
