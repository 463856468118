import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDfI4HMDC97FeFUQchQ16XIiahyLYrzNtM",
  authDomain: "krizbeatzmusicacademy-bff66.firebaseapp.com",
  databaseURL:
    "https://krizbeatzmusicacademy-bff66-default-rtdb.firebaseio.com",
  projectId: "krizbeatzmusicacademy-bff66",
  storageBucket: "krizbeatzmusicacademy-bff66.appspot.com",
  messagingSenderId: "539003233210",
  appId: "1:539003233210:web:19c24f590de7ad5de2f383",
  measurementId: "G-YGK6Q6YEBS",
};

//init firebase
firebase.initializeApp(firebaseConfig);

//init firestore
const db = firebase.firestore();

//init firebase auth
const auth = firebase.auth();

// timestamp
const timestamp = firebase.firestore.Timestamp;

export { db, auth, timestamp };
