import { useState, useEffect } from "react";
import { auth, db } from "../firebase/config";
import { useAuthContext } from "../hooks/useAuthContext";

export function useSignup() {
  const [isCancelled, setIsCancelled] = useState(false);
  const [error, setError] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const { dispatch } = useAuthContext();

  const signup = async (displayName, email, password, level) => {
    setError(null);
    setIsPending(true);

    try {
      const userCredentials = await auth.createUserWithEmailAndPassword(
        email,
        password
      );

      if (!userCredentials) {
        throw new Error("Could not complete authentication");
      }

      // Add displayName, phoneNumber, photoURL, providerId of user
      await userCredentials.user.updateProfile({ displayName });
      const userData = {
        uid: userCredentials.user.uid,
        displayName: userCredentials.user.displayName,
        email: userCredentials.user.email,
        level, // Assign the level value directly
        createdAt: new Date(),
      };

      await Promise.all([
        userCredentials.user.updateProfile({ displayName }),
        db.collection("students").doc(userCredentials.user.uid).set(userData),
        userCredentials.user.sendEmailVerification({
          url: "http://localhost:3000/login",
        }),
      ]);

      dispatch({
        type: "LOGIN",
        payload: userCredentials.user,
      });

      if (!isCancelled) {
        setIsPending(false);
        setError(null);
      }
    } catch (err) {
      if (!isCancelled) {
        setError(err.message);
        setIsPending(false);
      }
    }
  };

  useEffect(() => {
    return () => setIsCancelled(true);
  }, []);

  return { error, signup, isPending };
}
