import React from 'react'
import { useAuthContext } from '../hooks/useAuthContext'
import Avatar from './Avatar'

import './Navbar.css'

const Navbar = () => {
  const { user } = useAuthContext()

  return (
    <nav className='nav-container'>
      <div className='nav-contents'>
        <p>Hello, {user.displayName} </p>
        <Avatar />
      </div>
    </nav>
  )
}

export default Navbar
