import { ToastContainer } from "react-toastify";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import { useAuthContext } from "../../hooks/useAuthContext";
import { Navigate } from "react-router-dom";

import "./Dashboard.css";
import { Outlet } from "react-router-dom";

const Dashboard = () => {
  const { user, authIsReady } = useAuthContext();

  if (!authIsReady) {
    return null; // Render a loading state or any other UI while authentication is in progress
  }

  if (!user) {
    // Redirect to login if the user is not authenticated
    return <Navigate to="/login" />;
  }

  return (
    <div className="dashboard-container">
      <Sidebar />
      <div className="main-container">
        <Navbar />
        <Outlet />
        <ToastContainer />
      </div>
    </div>
  );
};

export default Dashboard;
