import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import "./App.css";
import Login from "./pages/login/Login";
import SignUp from "./pages/signup/SignUp";
import Dashboard from "./pages/dashboard/Dashboard";
import ResetPassword from "./pages/resetpassword/ResetPassword";
import { useAuthContext } from "./hooks/useAuthContext";
import "react-toastify/dist/ReactToastify.css";
import Home from "./pages/home/Home";
import DashboardFeed from "./components/DashboardFeed";
import Module1 from "./components/modules/Module1";
import Lessons from "./components/lessons/Lessons";

function App() {
  const { user, authIsReady } = useAuthContext();

  return (
    <>
      {authIsReady && (
        <Routes>
          <Route
            path="/"
            element={<Navigate to={user ? "/dashboard/feed" : "/home"} />}
          />
          <Route path="/home" element={<Home />} />
          <Route path="/dashboard" element={<Dashboard />}>
            <Route path="feed" element={<DashboardFeed />} />
            <Route path="modules" element={<Module1 />} />
            <Route path="lessons/:moduleId" element={<Lessons />} />
          </Route>
          <Route path="/signup" element={<SignUp />} />
          <Route path="/login" element={<Login />} />
          <Route path="/reset-password" element={<ResetPassword />} />
        </Routes>
      )}
    </>
  );
}

export default App;
